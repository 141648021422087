import React from 'react';

import {
    TimelineContainer,
    TimelineContainerLeft,
    TimelineContainerRight,
    TimelineContent,
    TimelineContentRight,
    TimelineHeading,
    TimelineLeftDate,
    TimelineLeftIcon,
    TimelineP,
    TimelineRightDate,
    TimelineRightIcon,
    FaHomeIcon,
    FaCheckIcon,
    FaGroupIcon,
    FaSpinnerIcon,
    TLHeader
} from './TimelineElements'

const Timeline = () => {
    return (
        <TimelineContainer id="roadmap">
        <TLHeader>Launch Sequence</TLHeader>
            <TimelineContainerLeft>
                <TimelineLeftDate>1</TimelineLeftDate>
                <TimelineLeftIcon><FaCheckIcon /></TimelineLeftIcon>
                <TimelineContent>
                    <TimelineHeading>Prepare for Lift Off</TimelineHeading>
                    <TimelineP>Programmatically generate art. Develop Smart Contract. Devlop Info site and Minter. </TimelineP>
                </TimelineContent>
            </TimelineContainerLeft>
            <TimelineContainerRight>
                <TimelineRightDate>2</TimelineRightDate>
                <TimelineRightIcon><FaGroupIcon /></TimelineRightIcon>
                <TimelineContentRight>
                    <TimelineHeading>Gather the Crowd</TimelineHeading>
                    <TimelineP>Spread the word. Community outreach. Giveaways. Increase Twitter and Discord following.</TimelineP>
                </TimelineContentRight>
            </TimelineContainerRight>
            <TimelineContainerLeft>
                <TimelineLeftDate>3</TimelineLeftDate>
                <TimelineLeftIcon><FaSpinnerIcon /></TimelineLeftIcon>
                <TimelineContent>
                    <TimelineHeading>Exclusive Training Site</TimelineHeading>
                    <TimelineP>Web3 Integrated exclusive training site. Holders access to video tutorials and source code.</TimelineP>
                </TimelineContent>
            </TimelineContainerLeft>
            <TimelineContainerRight>
                <TimelineRightDate>4</TimelineRightDate>
                <TimelineRightIcon><FaHomeIcon /></TimelineRightIcon>
                <TimelineContentRight>
                    <TimelineHeading>3 .. 2 .. 1 Blast Off</TimelineHeading>
                    <TimelineP>Minting Site live. SRP launch pad system to help holders market and develop a project of their own.</TimelineP>
                </TimelineContentRight>
            </TimelineContainerRight>
        </TimelineContainer>
    );
};

export default Timeline;