export const homeObjOne = {
  id: 'about',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Join To Learn',
  headline: 'Video tutorials/Source Code to create web3 dapps, smart contracts, and NFT projects',
  description:
    'Learn to code with real time developer support. Make dreams a reality all while building a future facing skill-set.',
  buttonLabel: 'Learn More',
  to: '//twitter.com/stargazerktNFT',
  imgStart: false,
  img: require('../../images/image-1.png'),
  alt: 'Car',
  dark: true,
  primary: true,
  darkText: false
};

export const homeObjTwo = {
  id: 'discover',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Developer Community',
  headline: 'Access our Developers to help with any coding needs',
  description:
    'We aim to build the largest NFT developer community. Real time support, both during development and marketing phases. Join a community that will support you on your journey to becoming a Developer!',
  buttonLabel: 'Grow Together on Discord',
  to: '//discord.gg/wFpP9f27gX',
  imgStart: true,
  img: require('../../images/image-3.png'),
  alt: 'Piggybank',
  dark: false,
  primary: false,
  darkText: true
};

export const homeObjThree = {
  id: 'signup',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Join our Team',
  headline: 'Join the whitelist today',
  description:
    "Joining the whitelist will include added benefits like pre mint giveaways, exclusive information on the project, and much more!",
  buttonLabel: 'Join Whitelist',
  to: '/signin',
  imgStart: false,
  img: require('../../images/image-2.png'),
  alt: 'Papers',
  dark: false,
  primary: false,
  darkText: true
};
