import React, {useState} from 'react';
import {
  Container,
  FormWrap,
  Icon,
  FormContent,
  Form,
  FormH1,
  FormLabel,
  FormInput,
  FormButton,
  Text
} from './SigninElements';

const SignIn = () => {
  const [data, setData] = useState({
    email: "",
    ethAddress: "",
    referral: "",
  });

  const {email, ethAddress, referral} = data

  const handleChange = (e) => {
    setData({...data, [e.target.name]: e.target.value});
  };

  const handleSumbit = async e => {
    e.preventDefault();

    try{
      const response = await fetch(
        'https://v1.nocodeapi.com/srpxoracle/google_sheets/vLnsKnQIVBOmfFLd?tabId=Sheet1', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify([[email, ethAddress, referral, new Date().toLocaleString()]])
        }
        );
        await response.json()
        setData({...data, email:'GREAT', ethAddress:'SUCCESS', referral:':)'})
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <>
      <Container>
        <FormWrap>
          <Icon to='/'>StargazeRocketProject</Icon>
          <FormContent>
            <Form onSubmit={handleSumbit}>
              <FormH1>Join the Whitelist</FormH1>
              <FormLabel htmlFor='email'>Contact: Twitter @ | Discord ID or Email</FormLabel>
              <FormInput type='text' name='email' value={email} onChange={handleChange} required />
              <FormLabel htmlFor='ethAddress'>ETH Address</FormLabel>
              <FormInput type='text' name='ethAddress' value={ethAddress} onChange={handleChange} required />
              <FormLabel htmlFor='referral'>Referral</FormLabel>
              <FormInput type='text' name='referral' value={referral} onChange={handleChange} required />
              <FormButton type='submit'>Submit</FormButton>
              <Text>Please add a way for us to contact you. We will only reach out to give updates on the project, or to let you know if you have won a giveaway.</Text>
              <Text>Join To Learn</Text>
            </Form>
          </FormContent>
        </FormWrap>
      </Container>
    </>
  );
};

export default SignIn;
