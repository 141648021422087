import styled from 'styled-components';
import { FaRocket, FaCheckCircle, FaPeopleCarry, FaSpinner } from 'react-icons/fa';

export const TimelineContainer = styled.div`
  position: relative;
  width: 100%;
  //max-width: 1140px;
  margin: 0 auto;
  padding: 15px 0;
  background-color: #010606;

  &:after {
    content: '';
    position: absolute;
    width: 2px;
    background: #010606;
    top: 80px;
    bottom: 80px;
    left: 50%;
    margin-left: -1px;
  }

  @media screen and (max-width: 768px) {
    &:after {
      display: none;
  }
  }
`;

export const TLHeader = styled.h1`
  display: flex;
  justify-content: center;
  font-size: 36px;
  color: #008b8b;
  padding: 20px;
`;

export const TimelineContainerLeft = styled.div`
  padding: 15px 30px;
  padding-left: 70px;
  position: relative;
  background: inherit;
  width: 50%;
  left: 0;

  &:after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    top: calc(50% - 8px);
    right: -8px;
    //background: #ffffff;
    border: 2px solid #7BB4D8;
    border-radius: 16px;
    z-index: 1;
  }

  &:before {
    content: '';
    position: absolute;
    width: 50px;
    height: 2px;
    top: calc(50% - 1px);
    right: 8px;
    background: #7BB4D8;
    z-index: 1;
  }

  @media screen and (max-width: 950px) {
    padding: 15px 30px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;  

    &:after {
      //left: 82px;
      border: none;
      padding: 15px 30px;
    }

    &:before {
        display: none;
    }
  }
`;

export const TimelineContainerRight = styled.div`
  padding: 15px 30px;
  padding-right: 70px;
  position: relative;
  background: inherit;
  width: 50%;
  left: 50%;

  &:after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    top: calc(50% - 8px);
    right: -8px;
    //background: #ffffff;
    border: 2px solid #7BB4D8;
    border-radius: 16px;
    z-index: 1;
    left: -8px;
  }

  &:before {
    content: '';
    position: absolute;
    width: 50px;
    height: 2px;
    top: calc(50% - 1px);
    right: 8px;
    background: #7BB4D8;
    z-index: 1;
    left: 8px;
  }

  @media screen and (max-width: 950px) {
    padding: 15px 30px;
  }

  @media screen and (max-width: 768px) {
    width: 100%; 
    left: 0%;
    border: none;

    &:after {
      //left: 82px;
      border: none;
      padding: 15px 30px;
    }

    &:before {
      display: none;
    }
  }
`;

export const TimelineLeftDate = styled.div`
  position: absolute;
  display: inline-block;
  top: calc(50% - 15px);
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #008b8b;
  text-transform: uppercase;
  letter-spacing: 1px;
  z-index: 1;
  right: -75px;

  @media screen and (max-width: 768px) {
    right: auto; left: 65px;
    font-size: 24px;
  }
`;

export const TimelineRightDate = styled.div`
  position: absolute;
  display: inline-block;
  top: calc(50% - 15px);
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #008b8b;
  text-transform: uppercase;
  letter-spacing: 1px;
  z-index: 1;
  left: -75px;

  @media screen and (max-width: 768px) {
    right: auto; left: 65px;
    font-size: 24px;
  }
`;

export const TimelineLeftIcon = styled.div`
  position: absolute;
  display: inline-block; 
  width: 40px; 
  height: 40px; 
  padding: 9px 0; 
  top: calc(50% - 20px); 
  background: #000000; 
  border: 2px solid #7BB4D8; 
  border-radius: 40px; 
  text-align: center; 
  font-size: 18px; 
  color: #7BB4D8; 
  z-index: 1;
  right: 56px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const FaHomeIcon = styled(FaRocket)`
  color: #fff;
`;

export const FaCheckIcon = styled(FaCheckCircle)`
  color: #fff;
`;

export const FaGroupIcon = styled(FaPeopleCarry)`
  color: #fff;
`;

export const FaSpinnerIcon = styled(FaSpinner)`
  color: #fff;
`;

export const TimelineRightIcon = styled.i`
  position: absolute;
  display: inline-block; 
  width: 40px; 
  height: 40px; 
  padding: 9px 0; 
  top: calc(50% - 20px); 
  background: #000000; 
  border: 2px solid #7BB4D8; 
  border-radius: 40px; 
  text-align: center; 
  font-size: 18px; 
  color: #7BB4D8; 
  z-index: 1;
  left: 56px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const TimelineContent = styled.div`
  padding: 30px 90px 30px 30px;
  background: #C0C0C0; 
  position: relative; 
  border-radius: 0 500px 500px 0; 

  @media screen and (max-width: 768px) {
    padding: 30px 30px 30px 90px; 
    border-radius: 500px;
  }

  @media screen and (max-width: 400px) {
    padding: 30px 30px 30px 90px; 
    border-radius: 50px;
  }
`;

export const TimelineContentRight = styled.div`
  padding: 30px 30px 30px 90px; 
  border-radius: 500px 0 0 500px;
  background: #C0C0C0; 
  position: relative;

  @media screen and (max-width: 768px) {
    padding: 30px 30px 30px 90px; 
    border-radius: 500px;
  }

  @media screen and (max-width: 400px) {
    padding: 30px 30px 30px 90px; 
    border-radius: 50px;
  }
`;

export const TimelineHeading = styled.h2`
  margin: 0 0 10px 0;
  font-size: 22px; 
  font-weight: bold; 
  color: #008b8b; 
`;

export const TimelineP = styled.p`
  margin: 0; 
  font-size: 16px; 
  line-height: 22px; 
  color: #000;
`;