import React, { useState } from 'react';
import { Button } from '../ButtonElements';

import Video from '../../images/bg.png';
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight
} from './HeroElements';

function HeroSection() {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };
  return (
    <HeroContainer id='home'>
      <HeroBg>
        <VideoBg src={Video} />
      </HeroBg>
      <HeroContent>
        <HeroH1>Embark On Your Journey</HeroH1>
        <HeroP>
          Join to learn how to become a web3 developer. Real developer support. Exclusive video tutorials, source code, and guidance.
        </HeroP>
        <HeroBtnWrapper>
          <Button
            href='//discord.gg/wFpP9f27gX'
            target='blank'
            onMouseEnter={onHover}
            onMouseLeave={onHover}
          >
            Join Discord {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
}

export default HeroSection;
